import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import { posthog } from '@/modules/base/composable/useAnalytics'
import type { CookieConsentConfig } from 'vanilla-cookieconsent'

const config: CookieConsentConfig = {
  revision: 0, // increase to show consent banner again
  mode: 'opt-out', // default: 'opt-in'
  autoShow: true, // default: true
  hideFromBots: true, // default: true
  disablePageInteraction: true, // default: false
  lazyHtmlGeneration: true, // default: true
  autoClearCookies: true,

  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'bottom center',
      flipButtons: false,
      equalWeightButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      flipButtons: false,
      equalWeightButtons: false,
    },
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
      services: {
        Sentry: {},
      },
    },
    optional: {
      enabled: true,
      services: {
        Posthog: {
          onAccept: () => posthog?.opt_in_capturing(),
          onReject: () => posthog?.opt_out_capturing(),
        },
      },
    },
  },

  language: {
    default: 'en',
    autoDetect: 'browser',

    translations: {
      en: {
        consentModal: {
          title: 'Cookie Consent',
          description:
            'Our service uses essential cookies to ensure its proper operation and cookies to understand how you interact with it.',
          acceptAllBtn: 'Accept',
          acceptNecessaryBtn: 'Reject',
          showPreferencesBtn: 'Manage preferences',
        },
        preferencesModal: {
          title: 'Cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Save preferences',
          sections: [
            {
              title: 'Strictly necessary cookies',
              description:
                'Our service uses essential technical debugging cookies to ensure its proper operation.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Optional cookies',
              description:
                'Our service uses cookies to understand how you interact with it.',
              linkedCategory: 'optional',
            },
          ],
        },
      },
      de: {
        consentModal: {
          title: 'Cookie Einwilligung',
          description:
            'Unser Service verwendet essentielle Cookies, um den ordnungsgemäßen Betrieb zu gewährleisten und um zu verstehen, wie Sie mit unserem Service interagieren.',
          acceptAllBtn: 'Akzeptieren',
          acceptNecessaryBtn: 'Ablehnen',
          showPreferencesBtn: 'Einstellungen verwalten',
        },
        preferencesModal: {
          title: 'Cookie Einstellungen',
          acceptAllBtn: 'Alle Akzeptieren',
          acceptNecessaryBtn: 'Alle Ablehnen',
          savePreferencesBtn: 'Einstellungen speichern',
          sections: [
            {
              title: 'Notwendige Cookies',
              description:
                'Unser Service verwendet essentielle technische Cookies, um den ordnungsgemäßen Betrieb zu gewährleisten.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Optionale Cookies',
              description:
                'Unser Service verwendet Cookies, um zu verstehen wie Sie mit unserem Service interagieren.',
              linkedCategory: 'optional',
            },
          ],
        },
      },
    },
  },
}

export const cookieConsent = () => CookieConsent.run(config)
